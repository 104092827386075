import * as React from "react"
import { graphql, Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import { Seo } from "../../components/seo"
import { Layout } from "../../components/layout"
import { Container } from "@mui/system"
import { useState } from "react"
import { Box, Grid, Grow, Typography } from "@mui/material"
import PortableText from "../../components/PortableText"
import { TransitionGroup } from "react-transition-group"
import {
  CustomBlackLeftArrowIcon,
  CustomBlackRightArrowIcon,
} from "../../components/CustomArrows"
import Slider from "react-slick"
import NotFoundPage from "../404"
import ImageDialog from "../../components/ImageDialog"

const CONTENT_WIDTH = 1150
const TEXT_COLUMN_WIDTH = 405
const MAIN_PIC_WIDTH = TEXT_COLUMN_WIDTH
const MAIN_PIC_HEIGHT = Math.round((MAIN_PIC_WIDTH * 3) / 2)
const MINI_PIC_HEIGHT = Math.round((MAIN_PIC_HEIGHT - 8 * 6) / 7)
const MINI_PIC_WIDTH = Math.round((MINI_PIC_HEIGHT * 2) / 3)

export default function Lookbook({
  data: {
    sanityLookbook: { images, slug, title, _rawDescription, active },
    sanitySiteSettings: { lookbooks },
  },
}) {
  const [selectedImage, setSelectedImage] = useState(0)
  const [archiveClick, setArchiveClick] = useState(false)
  const [zoomed, setZoomed] = React.useState(false)
  const getLink = (link) => {
    if (link)
      return link
        .replace("https://www.paradise4saigon.com", "")
        .replace("https://vi.paradise4saigon.com", "")

    return ""
  }
  const handleZoom = () => {
    setZoomed(true)
  }
  const renderedImages = images?.map((item, index) => (
    <Grid item xs={3} key={index} sx={{ height: MINI_PIC_HEIGHT + 8 }}>
      <Box
        onClick={() => setSelectedImage(index)}
        sx={{
          overflow: "hidden",
          position: "relative",
          width: "100%",
          height: "100%",
          // outline: index === selectedImage ? "3px solid black" : "",
        }}
      >
        {index === selectedImage && (
          <Box
            height="100%"
            width="100%"
            position="absolute"
            sx={{ boxSizing: "border-box", border: "3px solid black" }}
          />
        )}
        {images?.[index]?.image && images?.[index]?.image.asset ? (
          <SanityImage
            {...images[index].image}
            height={MAIN_PIC_HEIGHT}
            width={MAIN_PIC_WIDTH}
            alt={`Image #${index + 1}`}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#606060",
            }}
          ></div>
        )}
      </Box>
    </Grid>
  ))
  const currentImage = images?.[selectedImage]?.image

  const activeLookbooks = lookbooks.filter((item) => item.active)

  const nextItem = () => {
    if (selectedImage === (images?.length - 1 || 17)) setSelectedImage(0)
    else setSelectedImage((num) => num + 1)
  }

  const prevItem = () => {
    if (selectedImage === 0) setSelectedImage(images?.length - 1 || 17)
    else setSelectedImage((num) => num - 1)
  }
  if (!active) return <NotFoundPage />
  return (
    <Layout>
      <Seo title={title} />
      <ImageDialog
        zoomed={zoomed}
        setZoomed={setZoomed}
        selectNext={nextItem}
        selectPrev={prevItem}
        pic={images?.[selectedImage]?.image.asset.url + "?w=910&h=1216&dpr=2"}
      />
      <Container sx={{ justifyContent: "center", display: "flex", mb: 4 }}>
        <Box
          width={{ lg: CONTENT_WIDTH, xs: "100%" }}
          height={{ lg: MAIN_PIC_HEIGHT, xs: "100%" }}
          flexShrink={0}
          display="flex"
          px={{ xs: 1, lg: 0 }}
          justifyContent={{ xs: "center", lg: "space-between" }}
          alignItems={{ xs: "center", lg: "stretch" }}
          flexDirection={{ xs: "column", lg: "row" }}
        >
          <Box display={{ xs: "none", lg: "flex" }}>
            <Box
              width={{ xs: "100%", lg: MAIN_PIC_WIDTH }}
              maxWidth={MAIN_PIC_WIDTH}
              position="relative"
            >
              <Box
                maxHeight={MAIN_PIC_HEIGHT}
                height="100%"
                overflow="hidden"
                onClick={handleZoom}
                mb={{ xs: 3, lg: 0 }}
              >
                {currentImage ? (
                  <SanityImage
                    height={MAIN_PIC_HEIGHT * 3}
                    width={MAIN_PIC_WIDTH * 3}
                    {...images?.[selectedImage]?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      minHeight: 492,
                    }}
                    alt={"Image No." + (selectedImage + 1)}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#606060",
                    }}
                  />
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                position="absolute"
                alignItems="center"
                // bgcolor="white"
                width="100%"
                // bottom={0}
              >
                <CustomBlackLeftArrowIcon onClick={prevItem} />
                {selectedImage + 1} of {images?.length || 0}
                <CustomBlackRightArrowIcon onClick={nextItem} />
              </Box>
            </Box>
            <Box
              overflow={"hidden"}
              width={
                (MINI_PIC_WIDTH + 8) * 4 +
                (images?.length && images.length > 17 && 17)
              }
              sx={{
                maxHeight: MAIN_PIC_HEIGHT,
                overflow:
                  images?.length && images.length > 17 ? "auto" : "hidden",
              }}
              display={{ xs: "none", lg: "block" }}
              ml={2}
            >
              <Grid container spacing={1}>
                {renderedImages}
              </Grid>
            </Box>
          </Box>
          <Box
            width={{ xs: "100%", lg: TEXT_COLUMN_WIDTH }}
            maxWidth={TEXT_COLUMN_WIDTH}
            flexDirection="column"
            display="flex"
          >
            <Box display={{ xs: "none", lg: "block" }}>
              <div className="lookbook_title">{title || "NOTHING HERE!"}</div>
              {_rawDescription && <PortableText blocks={_rawDescription} />}
            </Box>
            <Box display={{ xs: "block", lg: "none" }}>
              <Slider
                {...lookbookSettings}
                afterChange={(newIndex) => setSelectedImage(newIndex)}
              >
                {images?.map(
                  (item, index) =>
                    item.image?.asset && (
                      <SanityImage
                        height={MAIN_PIC_HEIGHT}
                        width={MAIN_PIC_WIDTH}
                        {...item.image}
                        key={index}
                      />
                    )
                )}
              </Slider>
            </Box>
            <Box
              display={{ xs: "block", lg: "none" }}
              fontSize={12}
              padding="3px"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div
                  className="lookbook_title"
                  style={{
                    marginBottom: 0,
                    fontSize: 12,
                    top: 0,
                    lineHeight: "12px",
                    letterSpacing: -0.5,
                  }}
                >
                  {title || "NOTHING HERE!"}
                </div>
                {selectedImage + 1}/{images?.length || 0}
              </Box>
            </Box>
            <Box
              position="relative"
              zIndex={10}
              display="flex"
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              mt={{ lg: "auto", xs: 0 }}
              alignItems={{ xs: "none", lg: "flex-end" }}
              fontSize={14}
            >
              <Box maxWidth={{ xs: "none", lg: 180 }}>
                {images?.[selectedImage]?.products?.map((item) => (
                  <Link
                    key={item.url}
                    to={getLink(item.url) || null}
                    className="lookbook-item-link"
                  >
                    {item.text}
                  </Link>
                ))}
              </Box>
              {activeLookbooks.length > 0 && (
                <>
                  <Box
                    position="relative"
                    flex={1}
                    display={{ xs: "none", lg: "flex" }}
                    justifyContent="flex-end"
                  >
                    <Typography
                      fontSize={13}
                      padding="3px"
                      letterSpacing={-0.5}
                      className="archive-hover"
                    >
                      Archive*
                    </Typography>
                    <Box
                      className="lookbook-group"
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      position="absolute"
                      width={TEXT_COLUMN_WIDTH}
                      sx={{ transform: "translateY(-100%)" }}
                    >
                      {activeLookbooks.map((item) => (
                        <Link
                          to={"/lookbook/" + item.slug.current}
                          key={item.slug.current}
                          className="lookbook-item-link"
                        >
                          {item.title}
                        </Link>
                      ))}
                    </Box>
                  </Box>
                  <Box
                    position="relative"
                    flex={1}
                    display={{ xs: "block", lg: "none" }}
                  >
                    <Typography
                      fontSize={13}
                      padding="3px"
                      fontWeight="bold"
                      letterSpacing={-0.5}
                      className="archive-hover"
                      onClick={() => setArchiveClick(!archiveClick)}
                    >
                      Archive*
                    </Typography>
                    <TransitionGroup>
                      {archiveClick && (
                        <Grow>
                          <Box
                            display="flex"
                            flexDirection="column"
                            width={TEXT_COLUMN_WIDTH}
                          >
                            {activeLookbooks.map((item) => (
                              <Link
                                to={"/lookbook/" + item.slug.current}
                                key={item.slug.current}
                                className="lookbook-item-link"
                              >
                                {item.title}
                              </Link>
                            ))}
                          </Box>
                        </Grow>
                      )}
                    </TransitionGroup>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    sanitySiteSettings {
      lookbooks {
        title
        active
        slug {
          current
        }
      }
    }
    sanityLookbook(id: { eq: $id }) {
      _rawDescription
      active
      title
      slug {
        current
      }
      images {
        _key
        image {
          ...Image
          asset {
            url
          }
        }
        products {
          text
          url
        }
      }
    }
  }
`

const lookbookSettings = {
  dots: false,
  infinite: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
}
