import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box } from "@mui/material"
import React from "react"
import Magnifier from "react-magnifier"
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons"

const ImageDialog = ({ zoomed, setZoomed, selectNext, selectPrev, pic }) => {
  return (
    <Box
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        zIndex: 100,
        top: 0,
        left: 0,
        cursor: "zoom-out",
        display: zoomed ? "block" : "none",
        backgroundColor: "white",
      }}
    >
      <Box
        position="fixed"
        onClick={() => setZoomed(false)}
        padding={"15px"}
        top={0}
        right={0}
        cursor="pointer"
        zIndex={200}
      >
        <FontAwesomeIcon icon={faTimesCircle} size="3x" color="gray" />
      </Box>
      <Box height="100%" width="100%" display="flex">
        <Box flexGrow={1} className="left-cursor" onClick={selectPrev}></Box>
        <img src={pic} alt="" style={{ objectFit: "contain" }} />
        <Box flexGrow={1} className="right-cursor" onClick={selectNext} />
      </Box>
    </Box>
  )
}

export default ImageDialog
